<template>
  <div class="unOuter mainOuter">
    <div class="container_un animated fadeInLeft ani_delay05">
      <div class="subWhite">
        <div class="sub_bg_bot"></div>
        <div class="subTitBox">
          <p id="t_page_title" class="subTitle">{{$t('walletHistory.title')}}</p>
        </div>
        <div class="RightBox">
          <div id="wallet-balances--block">
            <ul>
              <li v-for="balance in balances" :class="{active: (balance.coin === 'TRX' ? 'TRON' : balance.coin) === coin}" @click="changeHistory(balance.coin === 'TRX' ? 'TRON' : balance.coin)">
                <p class="symbol" :class="balance.coin"><img :src="`/img/symbol/${(balance.coin === 'TRX' ? 'TRON' : balance.coin)}.png`" :alt="balance.coin" onerror="this.src='/img/symbol/default.png'" /></p>
                <p class="name">{{$t('withdrawal.balance')}}{{balance.coin === 'TRX' ? 'TRON' : balance.coin}}</p>
                <p class="balance">{{balance.balance}}</p>
              </li>
            </ul>
          </div>

          <div id="bonusList">
            <div class="icome-list-history ng-star-inserted">
              <table id="users" class="table accordion" aria-label="">
                <thead>
                <tr>
                  <th>{{$t('walletHistory.thDate')}}</th>
                  <th>{{$t('walletHistory.thType')}}</th>
                  <th>{{$t('walletHistory.thState')}}</th>
                  <th>{{$t('walletHistory.thBalance')}}</th>
                </tr>
                </thead>
                <tbody>
                <template v-for="(item,index) in list" class="accordion accordion-flush">
                  <tr data-bs-toggle="collapse" :data-bs-target="`#history-desc${index}`" class="ng-star-inserted">
                    <td> {{ dateFormat(item.create_time) }}</td>
                    <td> {{ CheckType(item.type) }}</td>
                    <td> {{ item.result }}</td>
                    <td> {{ item.balance }}</td>
                  </tr>
                  <tr :id="`history-desc${index}`" class="collapse accordion-collapse" data-bs-parent=".table">
                    <td colspan="4">
                      <table style="width: 100%" aria-label="">
                        <tbody>
                        <tr>
                          <td>{{$t('walletHistory.thFromAddress')}}</td>
                          <td>{{item.from}}</td>
                        </tr>
                        <tr>
                          <td>{{$t('walletHistory.thToAddress')}}</td>
                          <td>{{item.to}}</td>
                        </tr>
                        <tr>
                          <td>{{$t('walletHistory.thType')}}</td>
                          <td>{{ CheckType(item.type) }}</td>
                        </tr>
                        <tr>
                          <td>{{$t('walletHistory.thBalance')}}</td>
                          <td>{{item.balance}}</td>
                        </tr>
                        <tr>
                          <td>{{$t('walletHistory.thState')}}</td>
                          <td>{{item.result}}</td>
                        </tr>
                        <tr>
                          <td>{{$t('walletHistory.thDate')}}</td>
                          <td>{{dateFormat(item.create_time)}}</td>
                        </tr>
                        <tr>
                          <td>Hash</td>
                          <td><a :href="`https://tronscan.org/#/transaction/${item.hash}`" target="_blank">{{item.hash}}</a></td>
                        </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
            <ul class="pagination pagination-primary pagination-sm d-flex justify-content-center">
              <li class="page-item" v-if="max_page > 5">
                <a class="page-link" href="javascript:void(0);" @click="prevPage()">
                  <i class="fa fa-angle-left"></i>
                </a>
              </li>
              <li class="page-item " :class="checkPage(n)" v-for="n in max_page" :key="'Page_'+n">
                <a class="page-link" href="javascript:void(0);" @click="clickPaging(n)">
                  {{ n }}
                </a>
              </li>
              <li class="page-item" v-if="max_page > 5">
                <a class="page-link" href="javascript:void(0);" @click="nextPage()">
                  <i class="fa fa-angle-right"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import 'bootstrap/dist/js/bootstrap.min'
const CryptoJS = require("crypto-js");
const exactMath = require('exact-math');
import moment from "moment";
import * as https from "https";
export default {
  computed: {
    https() {
      return https
    }
  },
  data() {
    return {
      list: [],
      coin: 'TRON',
      balances: [],
      limit: 10,
      page: 1,
      max_page: 0,
      show: 3
    }
  },

  beforeMount() {
    this.PreloadWalletBalance()
  },

  mounted() {
    this.GetWalletInfo()
    this.getHistory(this.coin);
  },
  methods: {
    dateFormat(time) {
      return moment(time).format('YYYY-MM-DD h:mm:ss');
    },
    CheckType(type) {
      if (type === 'W') {
        return "출금"
      } else if (type === 'D') {
        return "입금"
      }
    },
    clickPaging(i) {
      this.page = i;
      const coin = this.coin;
      this.getHistory(coin)
    },
    checkPage(i) {
      const show = this.show;
      const page = this.page;
      var add_class = '';
      if ((i + 2) == show) {
        if (page == i) {
          add_class = " active ";
        }
      } else if ((i + 1) == show) {
        if (page == i) {
          add_class = " active ";
        }
      } else if ((i) == show) {
        if (page == i) {
          add_class = " active ";
        }
      } else if ((i - 1) == show) {
        if (page == i) {
          add_class = " active ";
        }
      } else if ((i - 2) == show) {
        if (page == i) {
          add_class = " active ";
        }
      } else {
        add_class += 'd-none';
      }

      return add_class;
    },
    prevPage() {
      const show = this.show;
      const show_prev = exactMath.sub(show, 1);

      if (show_prev >= 3) {
        this.show = show_prev;
      } else {
        this.show = show;
      }
    },
    nextPage() {
      const show = this.show;
      const show_next = exactMath.add(show, 1);

      const max = exactMath.sub(this.max_page, 2);

      if (show_next <= max) {
        this.show = show_next;
      } else {
        this.show = max;
      }
    },
    changeHistory(coin) {
      this.show = 3;
      this.page = 1;
      this.coin = coin;

      this.getHistory(coin);
    },

    PreloadWalletBalance() {
      this.$axios.post('/member/coin/GetActiveCoinListForHistory', {}).then(
          res => {
            if (res.status !== 200) {
              return
            }

            if (res.data.code === 9999) {
              this.$store.dispatch('SETLOGOUT').then(
                  () => {
                    window.location.href = "/sign/login";
                  }
              )

              return
            }

            const e_body = res.data.body;
            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
            const d_res = bytes.toString(CryptoJS.enc.Utf8);
            const body = JSON.parse(d_res)

            let balances = []
            for (const row of body.lists) {
              balances = balances.concat({
                coin: row,
                balance: 0
              })
            }

            this.balances = balances
          }
      )
    },

    GetWalletInfo(){

      this.$axios.post('/member/coin/GetWalletInfo', {}).then(
          res => {
            if(res.status ==200){
              if(res.data.code=='200'){
                const e_body = res.data.body;
                const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                const d_res = bytes.toString(CryptoJS.enc.Utf8);
                const body = JSON.parse(d_res)

                this.balances = body.wallet.balances
              }else if(res.data.code=='9999'){
                this.$store.dispatch('SETLOGOUT').then(
                    ()=>{
                      window.location.href="/sign/login";
                    }
                )
              }
            }
          }
      )
    },

    getHistory(coin) {
      this.coin = coin;
      const page = this.page;
      const limit = this.limit;
      const body = {coin, page, limit};

      const req = CryptoJS.AES.encrypt(JSON.stringify(body),
          process.env.VUE_APP_SECRET_TOKEN).toString();

      const path = "/member/coin/history";

      this.$axios.post(path, {req}).then(
          res => {
            if (res.status === 200) {
              if (res.data.code === 200) {
                const e_body = res.data.body;
                const bytes = CryptoJS.AES.decrypt(e_body, process.env.VUE_APP_SECRET_TOKEN);
                const d_res = bytes.toString(CryptoJS.enc.Utf8);
                const body = JSON.parse(d_res);
                this.list = body.list;
                this.max_page = body.count;
              } else if (res.data.code === 9999) {
                this.$store.dispatch('SETLOGOUT').then(
                    () => {
                      window.location.href = "/sign/login";
                    }
                )
              }
            } else {
              this.loading = false;
            }
          }
      )
    },
  }
}
</script>

<style lang="scss" scoped>
.pagination > li > a {
  color: #0d6efd;
}

.active>.page-link {
  color: #fff;
}

.tab {
  color: gray;
  font-weight: 600;

  &.active {
    color: goldenrod;
  }
}

.icome-list-history {
  #users {
    font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin: 12px 0px;
    border-radius: 15px;
  }
}

@media (min-width: 300px) {
  .hide {
    display: none !important;
  }
  .small-add {
    max-width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

@media (min-width: 700px) {
  .hide {
    margin: auto;
    display: table-cell !important;
    text-align: center;
  }
  .small-add {
    max-width: 100%;
  }
}

@media screen and (max-width: 550px) {
  .intxt01 {
    width: 70px !important;
  }
}

.show-mobile-menu {
  right: 0;
}

.hide-mobile-menu {
  right: 1;
}

.collapse {
  &:not(.show) {
    display: none;
  }
}

#users th {
  color: #000;
}
</style>

<style lang="scss" scoped>
@import "@/assets/css/wallet";
</style>
